import React from "react";
import Settings from "./pages/Settings";
import ConsentListPage from "./pages/ConsentListPage";
import BrandingPage from "./pages/BrandingPage/BrandingPage";
import ApiKeyPage from "./pages/ApiKeyPage/ApiKeyPage";

export const navigationLinks = [
  {
    id: "consents",
    route: "/consents/list",
    text: "Consents",
    isExact: true,
  },
  {
    id: "settings",
    route: "/settings",
    text: "Settings",
    isExact: true,
  },
];

type RouteType = {
  id: string;
  path: string;
  element: React.ReactElement;
};
export const routes: RouteType[] = [
  {
    id: "consents",
    path: "/consents/list",
    element: <ConsentListPage />,
  },
  {
    id: "settings",
    path: "/settings",
    element: <Settings />,
  },
  {
    id: "branding",
    path: "/branding",
    element: <BrandingPage />,
  },
  {
    id: "apikeys",
    path: "/apikeys",
    element: <ApiKeyPage />,
  },
];

export default {
  navigationLinks,
  routes,
};
